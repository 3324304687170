<template>
    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 pb-2">
                <span class="card-title DayHeading"> Overview</span>

            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 pb-2 text-right" v-bind:key="randerDropdown">

                <button class="dropdown-toggle btn-md btn-round   " style="background-color:transparent" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{overView}}
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" v-on:click="OverViewFilterFunction('Monthly')" href="javascript:void(0);">Monthly </a>
                    <a class="dropdown-item" v-on:click="OverViewFilterFunction('3 Month')" href="javascript:void(0);">3 Month</a>
                    <a class="dropdown-item" v-on:click="OverViewFilterFunction('6 Month')" href="javascript:void(0);">6 Month</a>
                    <a class="dropdown-item" v-on:click="OverViewFilterFunction('Year')" href="javascript:void(0);">1 Year</a>

                </div>

            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card card-stats">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-8 col-md-8 pt-2 pb-2">
                                <div class="text-left ">
                                    <span class="DashboardFontSize" style="padding-bottom:0px !important;">Cash</span><br />
                                    <span class="NumberSize">{{currency}} {{(parseFloat(cash)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>

                                </div>

                            </div>
                            <div class="col-4 col-md-4 pt-2 pb-2 ">
                                <div class="icon-big text-center icon-warning">
                                    <img src="DashboardIcons/Gross.png" />

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card card-stats">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-8 col-md-8 pt-2 pb-2">
                                <div class="text-left ">
                                    <span class="DashboardFontSize" style="padding-bottom:0px !important;">Bank Balance</span><br />
                                    <span class="NumberSize">{{currency}} {{(parseFloat(banks)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>

                                </div>

                            </div>
                            <div class="col-4 col-md-4 pt-2 pb-2">
                                <div class="icon-big text-center icon-warning">
                                    <img src="DashboardIcons/Prucahse.png" />

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card card-stats">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-8 col-md-8 pt-2 pb-2 ">
                                <div class="text-left ">
                                    <span class="DashboardFontSize" style="padding-bottom:0px !important;">Account Receivable</span><br />
                                    <span class="NumberSize">{{currency}} {{(parseFloat(accountReceivable)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>

                                </div>

                            </div>
                            <div class="col-4 col-md-4 pt-2 pb-2 ">
                                <div class="icon-big text-center icon-warning">
                                    <img src="DashboardIcons/Prucahse.png" />

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card card-stats">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-8 col-md-8 pt-2 pb-2 ">
                                <div class="text-left">
                                    <span class="DashboardFontSize" style="padding-bottom:0px !important;">Account Payable</span><br />
                                    <span class="NumberSize">{{currency}} {{(parseFloat(accountPayable)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}} </span>

                                </div>

                            </div>
                            <div class="col-4 col-md-4 pt-2 pb-2 ">
                                <div class="icon-big text-center icon-warning">
                                    <img src="DashboardIcons/Gross.png" />

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="card">
                    <div class="card-header">
                        <span class="card-title DayHeading">Account Receivable and Payable </span>

                        <div class="card-body mx-auto col-12" id="charts">

                            <apexchart type="area" height="350" v-bind:key="randerChart" :options="chartOptionsPayableAccount" :series="seriesPayableAccount"></apexchart>

                        </div>
                    </div>

                </div>


            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card ">

                    <div class="card-header ">

                        <span class="card-title DayHeading">VAT OverView </span>
                        <div class="card-body">



                            <div class="text-left row ">
                                <div class="col-6 col-md-6">
                                    <span class="DashboardFontSize" style="padding-bottom:0px !important;">Vat  Payable</span><br />
                                    <span class="NumberSizeForVat">{{currency}} {{(parseFloat(vatPayable)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>

                                </div>
                                <div class="col-6 col-md-6">
                                    <apexchart type="line" height="35" width="100" :options="chartOptions1" :series="series1"></apexchart>
                                </div>




                            </div>



                            <div class="text-left row ">
                                <div class="col-6 col-md-6">
                                    <span class="DashboardFontSize" style="padding-bottom:0px !important;">Vat  Receivable</span><br />
                                    <span class="NumberSizeForVat">{{currency}} {{(parseFloat(vatReceivable)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>

                                </div>
                                <div class="col-6 col-md-6">
                                    <apexchart type="line" height="35" width="100" :options="chartOptions1" :series="series1"></apexchart>
                                </div>


                            </div>
                        </div>


                    </div>

                    <div class="card-header ">

                        <span class="card-title DayHeading">Advance Payments  </span>
                        <div class="card-body">



                            <div class="text-left row ">
                                <div class="col-6 col-md-6">
                                    <span class="DashboardFontSize" style="padding-bottom:0px !important;">Advance Payable</span><br />
                                    <span class="NumberSizeForVat">{{currency}} {{(parseFloat(advancePayable)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>

                                </div>
                                <div class="col-6 col-md-6">
                                    <apexchart type="line" height="35" width="100" :options="chartOptions1" :series="series1"></apexchart>
                                </div>




                            </div>



                            <div class="text-left row ">
                                <div class="col-6 col-md-6">
                                    <span class="DashboardFontSize" style="padding-bottom:0px !important;">Advance  Receivable</span><br />
                                    <span class="NumberSizeForVat">{{currency}} {{(parseFloat(advanceReceivable)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>

                                </div>
                                <div class="col-6 col-md-6">
                                    <apexchart type="line" height="35" width="100" :options="chartOptions1" :series="series1"></apexchart>
                                </div>


                            </div>
                        </div>


                    </div>

                </div>


            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="card">
                    <div class="card-header">
                        <span class="card-title DayHeading"> Income VS Expense  </span>

                        <div class="card-body mx-auto col-12" id="charts">

                            <apexchart type="donut" width="380" height="350" :options="chartOptions" :series="series"></apexchart>

                        </div>
                    </div>

                </div>


            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="card">
                    <div class="card-header">
                        <span class="card-title DayHeading">Income Vs Expense  </span>

                        <div class="card-body mx-auto col-12" id="charts">

                            <apexchart type="bar" height="350" :options="chartOptions2" :series="series2" v-bind:key="randerChart"></apexchart>

                        </div>
                    </div>

                </div>


            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="card">
                    <div class="card-header">
                        <span class="card-title DayHeading"> Expense  </span>

                        <div class="card-body mx-auto col-12" id="charts">

                            <apexchart type="bar" height="350" :options="expensechartOptions" :series="expenseSeries" v-bind:key="randerChart"></apexchart>

                        </div>
                    </div>

                </div>


            </div>
            
            <!--<div class="col-lg-6 col-md-6 col-sm-6">
        <div class="card ">

            <div class="card-header ">

                <span class="card-title DayHeading">Income and Expense </span>
                <div class="row">

                    <div class="col-12 col-md-12 pt-2 pb-2">

                        <div class="text-left row ">
                            <div class="col-6 col-md-6">
                                <span class="DashboardFontSize" style="padding-bottom:0px !important;color:#3178F6;font-weight:bold">Income</span><br />
                                <div v-for="inv in incomeList" v-bind:key="inv.amount" >
                                 <span style="font-size:20px;font-weight:bold"> {{inv.costCenter}} <span style="color:#3178F6;float:right;">{{currency}} {{(parseFloat(inv.amount)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></span>


                                </div>

                            </div>
                            <div class="col-6 col-md-6">
                                <span class="DashboardFontSize" style="padding-bottom:0px !important;color:#3178F6;font-weight:bold">Expense</span><br />
                                <div v-for="inv in expenseList" v-bind:key="inv.amount">
                                    <span style="font-size:20px;font-weight:bold"> {{inv.costCenter}} <span style="color:#3178F6;float:right;font-weight:bold"> {{currency}} {{(parseFloat(inv.amount)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span></span>

                                </div>
                            </div>




                        </div>

                    </div>


                </div>


            </div>

        </div>


    </div>-->




        </div>
        <!--<loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>-->

    </div>
</template>
<script>

    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";
    //import Loading from 'vue-loading-overlay';
    //import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        props: ["active"],
        name: 'AccountDashboard',
      
        mixins: [clickMixin],

        data: function () {

            return {
                overView: 'Year',
                currency: '',
                incomeList: [],
                expenseList: [],
                randerChart: 0,
                randerDropdown: 0,
                cash: 0,
                banks: 0,
                vatPayable: 0,
                vatReceivable: 0,
                advancePayable: 0,
                advanceReceivable: 0,
                accountPayable: 0,
                accountReceivable: 0,
                totalInvoices: 0,
                totalReturn: 0,
                expense: 0,
                purchase: 0,
                creditAmount: 0,
                series: [],
                chartOptions: {
                    labels: ['Income','Expense']
                },
              
                expenseSeries: [{
                    name: 'Expense',
                    data: []
                }],
                
                expensechartOptions: {
                    chart: {
                        type: 'bar',
                        height: 380
                    },
                    plotOptions: {
                        bar: {
                            barHeight: '100%',
                            distributed: true,
                            horizontal: true,
                            dataLabels: {
                                position: 'bottom'
                            },
                        }
                    },
                    colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
                        '#f48024', '#69d2e7'
                    ],
                    dataLabels: {
                        enabled: true,
                        textAnchor: 'Ahsan',
                        style: {
                            colors: ['black']
                        },
                        formatter: function (val, opt) {
                            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                        },
                        offsetX: 0,
                        dropShadow: {
                            enabled: true
                        }
                    },
                    stroke: {
                        width: 1,
                        colors: ['#fff']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        labels: {
                            show: false
                        }
                    },
                    title: {
                        text: 'Expense ',
                        align: 'center',
                        floating: true
                    },
                    subtitle: {
                        text: 'Cost Center of Expense Account',
                        align: 'center',
                    },
                    tooltip: {
                        theme: 'dark',
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                                formatter: function () {
                                    return ''
                                }
                            }
                        }
                    }
                },

                chartOptionsPaidInvoices: {
                    labels: ['Vat Payable', 'VAT Receivable']
                },
                paidInvoicesSeries: [343, 34434],
                loading: false,
                seriesPayableAccount: [{
                    name: 'Receivable',
                    data: []
                }, {
                    name: 'Payable',
                    data: []
                }],
                chartOptionsPayableAccount: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: []
                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yy HH:mm'
                        },
                    },
                },
                userID: 0,
                employeeId: 0,
                isChartLoad: false,
                fromDate: moment().format("DD MMM YYYY"),
                toDate: Date(),
                series2: [{
                    name: 'Income',
                    data: []
                },
                    {
                    name: 'Expense',
                    data: []
                
                     }],
            chartOptions2: {
                chart: {
                    type: 'bar',
                        height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                            columnWidth: '55%',
                                endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                        width: 2,
                            colors: ['transparent']
                },
                xaxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    }
            },

            series1: [{
                data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
                }],
                series3: [{
                    data: [54, 9, 36, 12, 44, 25, 63, 89, 41, 66,25 ]
            }],

                chartOptions1: {
                chart: {
                    type: 'line',
                        width: 100,
                            height: 35,
                                sparkline: {
                        enabled: true
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return ''
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
                },
                chartOptions3: {
                chart: {
                    type: 'line',
                        width: 100,
                            height: 35,
                                sparkline: {
                        enabled: true
                    }
                },
                tooltip: {
                    fixed: {
                        enabled: false
                    },
                    x: {
                        show: false
                    },
                    y: {
                        title: {
                            formatter: function () {
                                return ''
                            }
                        }
                    },
                    marker: {
                        show: false
                    }
                }
            },


        }
    },
    watch: {

    },
    methods: {

        OverViewFilterFunction: function (x) {
            this.loading = false;
            debugger;
            this.overView = x;
            this.GetCashTransaction();
        },

        getDate: function (date) {
            return moment(date).format('l');
        },

        GetCashTransaction: function () {
            
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            
            this.$https.get('/Company/GetAccountTransaction?overViewFilter=' + this.overView, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                if (response.data != null) {
                    
                    var totalIncome = 0;
                    var totalExpense = 0;
                    root.loading = false;
                    root.expenseSeries[0].data = [];
                    root.series2[0].data = [];
                    root.series = [];
                    root.chartOptions.labels = [];
                    root.series2[1].data = [];
                    root.seriesPayableAccount[0].data = [];
                    root.seriesPayableAccount[1].data = [];
                    root.incomeList = [];
                    root.expenseList = [];
                    root.chartOptionsPayableAccount.xaxis.categories = [];
                    root.expensechartOptions.xaxis.categories = [];
                    root.banks = response.data.banks;
                    root.vatReceivable = response.data.vatReceivable;
                    root.vatPayable = response.data.vatPayable;
                    root.advancePayable = response.data.advancePayable;
                    root.accountPayable = response.data.accountPayable;
                    root.accountReceivable = response.data.accountReceivable;
                    root.advanceReceivable = response.data.advanceReceivable;
                    root.cash = response.data.cash;
                    root.incomeList = response.data.incomeList;
                    root.expenseList = response.data.expenseList;
                    
                    response.data.expenseList.forEach(function (result) {
                        root.expenseSeries[0].data.push(result.amount);
                        totalExpense = totalExpense + result.amount;
                        root.expensechartOptions.xaxis.categories.push(result.costCenter);
                    });
                    response.data.incomeList.forEach(function (result) {
                        totalIncome = totalIncome + result.amount;
                       
                    });
                    root.series.push(totalIncome);
                    root.series.push(totalExpense);
                    response.data.incomesAndExpenseList.forEach(function (result) {
                        root.series2[0].data.push((parseFloat(result.incomeAmount)).toFixed(0));
                        root.series2[1].data.push(parseFloat(result.expenseAmount).toFixed(0));
                    });

                    response.data.payableReceivableLookUpModel.forEach(function (result) {
                        root.seriesPayableAccount[0].data.push((parseFloat(result.receivableAmount)).toFixed(0));
                        root.seriesPayableAccount[1].data.push(parseFloat(result.payableAmount).toFixed(0));
                        root.chartOptionsPayableAccount.xaxis.categories.push(result.date);
                    });

                    root.randerChart++;


                }
            });
        },


    },
    created: function () {

        this.$emit('input', this.$route.name);
    },
    mounted: function () {
        
        if (this.active == 'Account') {
            this.currency = localStorage.getItem('currency');

            this.fromDate = moment().startOf('month').format("DD MMM YYYY");

            if (this.$session.exists()) {
                this.userID = localStorage.getItem('UserID');
                this.employeeId = localStorage.getItem('EmployeeId');
                this.fromDate = moment().startOf('month').format("DD MMM YYYY");

            }
            this.GetCashTransaction();

            this.rander++;
            this.randerDropdown++;
        }



    },
    }
</script>
<style scoped>

    .DashboardFontSize {
        font-size: 20px;
        color: black;
        font-weight: bold;
    }

    .NumberSize {
        font-size: 30px;
        font-weight: bold;
        color: #3178F6;
    }
    .NumberSizeForVat {
        font-size: 22px;
        font-weight: bold;
        color: #3178F6;
    }
</style>