<template>
    <div class="row" v-if="isValid('CanViewEmployeeReg')" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
        <div class="col-md-8 ml-auto mr-auto"  v-bind:class="$i18n.locale == 'en' ? '' : 'arabicLanguage'">
            <div class="row mb-4" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <h5 class="page_title">Employee Attendence Detail</h5>

                </div>
                <div class=" col-sm-6 col-md-6 col-lg-6">
                    <div v-bind:class="$i18n.locale == 'en' ? 'text-right' : 'text-left'">
                        <router-link :to="'/ManualAttendance'"><a href="javascript:void(0)" class="btn btn-outline-danger "> {{ $t('EmployeeRegistration.Close') }}</a></router-link>
                    </div>

                </div>

            </div>
            <div class="card">
                <div class="card-body">
                    <div class="col-lg-12">
                        <div class="mt-2">
                            <div class=" table-responsive">
                                <table class="table table-striped table-hover table_list_bg" v-bind:class="$i18n.locale == 'en' ? 'text-left' : 'arabicLanguage'">
                                    <thead class="m-0">
                                        <tr>
                                            <th class="text-center">
                                                #
                                            </th>
                                            <th class="text-center">
                                                Employee Name
                                            </th>
                                           
                                         
                                          
                                            <th class="text-center">
                                               Check In
                                            </th>
                                            <th class="text-center">
                                               Check Out
                                            </th>
                                            <th class="text-center">
                                              Total Hour
                                            </th>

                                            <th class="text-center">
                                               Over Time
                                            </th>
                                          
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(details,index) in employeelist" v-bind:key="details.id">
                                            <td class="text-center">
                                                {{index+1}}
                                            </td>
                                            
                                            <td class="text-center">
                                                {{details.employeeName}}
                                            </td>
                                           
                                            <td class="text-center">{{convertDate(details.checkIn)}}</td>
                                            <td class="text-center">{{convertDate(details.checkOut)}}</td>
                                            <td class="text-center">{{SubtractDateTime(details.checkIn,details.checkOut)}}</td>
                                            <td class="text-center">{{OverTime(details.companyTimeIn,details.companyTimeOut,details.checkIn,details.checkOut)}}</td>


                                           
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else> <acessdenied></acessdenied></div>
</template>

<script>
    import clickMixin from '@/Mixins/clickMixin'
    import moment from 'moment'

    export default {
        mixins: [clickMixin],
        data: function () {
            return {
                arabic: '',
                english: '',
                searchQuery: '',
                employeelist: [],
                search: '',
            }
        },
       
        methods: {
            convertDate: function (date) {
                if (date == undefined || date == null) {
                    return "";
                }
                return moment(date).format('ddd DD-MMM-YYYY, hh:mm A');
            },
            OverTime: function (companyTimeIn, companyTimeOut, checkIn, checkOut) {
             
                if (companyTimeOut == undefined || companyTimeOut == null || companyTimeIn == undefined || companyTimeIn == null) {
                    return "";
                }
                if (checkIn == undefined || checkIn == null || checkOut == undefined || checkOut == null) {
                    return "";
                }
                //Company Time
                const startTimeCompany = moment(companyTimeIn);
                const endTimeCompany = moment(companyTimeOut);
                const durationOfCompany = moment.duration(endTimeCompany.diff(startTimeCompany));
                const hoursCompany = parseInt(durationOfCompany.asHours());
                const minutesCompany = parseInt(durationOfCompany.asMinutes()) % 60;

                //EmployeeTime
                const startTime = moment(checkIn);
                const endTime = moment(checkOut);
                const duration = moment.duration(endTime.diff(startTime));
                const hours = parseInt(duration.asHours());
                const minutes = parseInt(duration.asMinutes()) % 60;

                if (hoursCompany > hours) {
                    return '--';
                }
                else {
                    const hh = parseInt(hours) - parseInt(hoursCompany) ;
                    const mm = parseInt(minutes) - parseInt(minutesCompany);
                    return hh + ':'+ mm;
                }

               

               
            },
            SubtractDateTime: function (x, y) {
               
                if (x == undefined || x == null) {
                    return "";
                }
                if (y == undefined || y == null) {
                    return "";
                }

                const startTime = moment(x);
                const endTime = moment(y);
                const duration = moment.duration(endTime.diff(startTime));
                const hours = parseInt(duration.asHours());
                const minutes = parseInt(duration.asMinutes()) % 60;

                return (hours +':'+ minutes)

               
            },

        },
        created: function () {
           
            this.$emit('input', this.$route.name);
            if (this.$route.query.data != undefined) {

                this.employeelist= this.$route.query.data;

            }
           
        },
        mounted: function () {
            this.english = localStorage.getItem('English');
            this.arabic = localStorage.getItem('Arabic');
        }
    }
</script>

<style scoped>
    .tbl_head tr th {
        padding-bottom: 0;
    }
</style>