<template>
    <div v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'text-right'">
        <div class="row">
            <div class="col-12 col-md-12 col-sm-12" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? '' : 'arabicLanguage'">
                <ul class="nav nav-tabs" data-tabs="tabs">
                    <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Dashboard'}" v-on:click="makeActive('Dashboard')" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Dashboard</a></li>
                    <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Account'}" v-on:click="makeActive('Account')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Account</a></li>
                    <li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'Inventory'}" v-on:click="makeActive('Inventory')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Inventory</a></li>
                    <!--<li class="nav-item"><a class="nav-link" v-bind:class="{active:active == 'CashAndBank'}" v-on:click="makeActive('CashAndBank')" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Cash And Bank</a></li>-->

                </ul>
            </div>
            <div  class="tab-content mt-3 col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 " id="nav-tabContent">
                <div v-if="active == 'Dashboard'">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 pb-2">
                            <span class="card-title DayHeading"> Overview</span>

                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 pb-2 text-right" v-bind:key="randerDropdown">

                            <button class="dropdown-toggle btn-md btn-round   " style="background-color:transparent" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{overView}}
                            </button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" v-on:click="OverViewFilterFunction('Today')" href="javascript:void(0);">Today </a>
                                <a class="dropdown-item" v-on:click="OverViewFilterFunction('Weekly')" href="javascript:void(0);">Weekly</a>
                                <a class="dropdown-item" v-on:click="OverViewFilterFunction('Monthly')" href="javascript:void(0);">Monthly</a>
                                <a class="dropdown-item" v-on:click="OverViewFilterFunction('6 Monthly')" href="javascript:void(0);">6 Monthly</a>

                            </div>

                        </div>
                        <div class=" col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <div class="card card-stats">
                                <div class="card-body ">
                                    <div class="row">
                                        <div class="col-xl-8 col-lg-8  col-md-8 col-8  pt-2 pb-2">
                                            <div class="text-left ">
                                                <span class="DashboardFontSize" style="padding-bottom:0px !important;">Sales</span><br />
                                                <span class="NumberSize">{{currency}} {{(parseFloat(income)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>

                                            </div>

                                        </div>
                                        <div class="col-xl-4 col-lg-4  col-md-4 col-4 pt-2 pb-2">
                                            <div class="icon-big text-center icon-warning">
                                                <img src="DashboardIcons/Prucahse.png" />

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="card card-stats">
                                <div class="card-body ">
                                    <div class="row">
                                        <div class="col-8 col-md-8 pt-2 pb-2 ">
                                            <div class="text-left ">
                                                <span class="DashboardFontSize" style="padding-bottom:0px !important;">Expense</span><br />
                                                <span class="NumberSize">{{currency}} {{(parseFloat(expense)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>

                                            </div>

                                        </div>
                                        <div class="col-4 col-md-4 pt-2 pb-2 ">
                                            <div class="icon-big text-center icon-warning">
                                                <img src="DashboardIcons/Prucahse.png" />

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="card card-stats">
                                <div class="card-body ">
                                    <div class="row">
                                        <div class="col-8 col-md-8 pt-2 pb-2 ">
                                            <div class="text-left">
                                                <span class="DashboardFontSize" style="padding-bottom:0px !important;">Credit</span><br />
                                                <span class="NumberSize">{{currency}} {{(parseFloat(creditAmount)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}} </span>

                                            </div>

                                        </div>
                                        <div class="col-4 col-md-4 pt-2 pb-2 ">
                                            <div class="icon-big text-center icon-warning">
                                                <img src="DashboardIcons/Gross.png" />

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-6">
                            <div class="card card-stats">
                                <div class="card-body ">
                                    <div class="row">
                                        <div class="col-8 col-md-8 pt-2 pb-2">
                                            <div class="text-left ">
                                                <span class="DashboardFontSize" style="padding-bottom:0px !important;">Purchase</span><br />
                                                <span class="NumberSize">{{currency}} {{(parseFloat(purchase)).toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}}</span>

                                            </div>

                                        </div>
                                        <div class="col-4 col-md-4 pt-2 pb-2 ">
                                            <div class="icon-big text-center icon-warning">
                                                <img src="DashboardIcons/Gross.png" />

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-12">
                            <div class="card">
                                <div class="card-header">
                                    <span class="card-title DayHeading">Earning</span>


                                    <div class="card-body mx-auto" id="charts">
                                        <div class="row">
                                            <div class="col-8 col-md-8 ">
                                                <apexchart type="area" height="350" v-bind:key="randerChart" :options="earningChartOption" :series="earningSeries"></apexchart>

                                            </div>
                                            <div class="col-4 col-md-4 ">
                                                <div class="row">
                                                    <div class="col-6 col-md-6 pt-2 pb-2 text-right ">
                                                        <div class="pt-3">
                                                            <span style="padding-bottom:0px !important;font-size:15px;font-weight: bold">No of Sales</span><br />
                                                            <span style="padding-bottom:0px !important;font-size:20px;color: #3178F6;font-weight: bold"> {{totalInvoices}}</span>

                                                        </div>



                                                    </div>
                                                    <div class="col-6 col-md-6 pt-2 pb-2 pt-3 ">
                                                        <div class="icon-big text-center icon-warning">
                                                            <a href="javascript:void(0)" class="btn btn-round btn-primary ">  <i class="fas fa-arrow-up "></i> </a>

                                                        </div>

                                                    </div>
                                                    <div class="col-6 col-md-6 pt-2 pb-2 text-right ">

                                                        <div class="pt-3">
                                                            <span style="padding-bottom:0px !important;font-size:15px;font-weight: bold">No of Sale Return</span><br />
                                                            <span style="padding-bottom:0px !important;font-size:20px;color: #3178F6;font-weight: bold"> {{totalReturn}}</span>

                                                        </div>


                                                    </div>
                                                    <div class="col-6 col-md-6 pt-2 pb-2 ">

                                                        <div class="icon-big text-center icon-warning pt-3">
                                                            <a href="javascript:void(0)" class="btn btn-round btn-primary ">  <i class="fas fa-arrow-down "></i> </a>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="card">
                                <div class="card-header">
                                    <span class="card-title DayHeading">Sale /Purchase Overview</span>

                                    <div class="card-body mx-auto col-12" id="charts">

                                        <apexchart type="area" height="350" v-bind:key="randerChart" :options="chartOptionsPurchase" :series="seriesPurchase"></apexchart>

                                    </div>
                                </div>

                            </div>


                        </div>
                        <div class="col-xl-3 col-lg-3  col-md-6 col-sm-6">
                            <div class="card">
                                <div class="card-header">
                                    <span class="card-title DayHeading">Invoice Overview</span>

                                    <div class="card-body mx-auto col-12" id="charts">

                                        <apexchart type="donut" width="380" height="350" :options="chartOptions" :series="series9"></apexchart>

                                    </div>
                                </div>

                            </div>


                        </div>

                        <div class="col-xl-3 col-lg-3  col-md-6 col-sm-6">
                            <div class="card">
                                <div class="card-header">
                                    <span class="card-title DayHeading">Paid/Un-Paid Invoices</span>

                                    <div class="card-body mx-auto col-12" id="charts">

                                        <apexchart type="donut" width="380" height="350" :options="chartOptionsPaidInvoices" :series="paidInvoicesSeries"></apexchart>

                                    </div>
                                </div>

                            </div>


                        </div>
                       
                    </div>


                </div>
                <div v-if="active == 'Account'" >

                    <AccountDashboard :active="'Account'"></AccountDashboard>

                </div>
                <div v-if="active == 'Inventory'">

                    <InventoryDashboard :active="'Inventory'"></InventoryDashboard>
                </div> 
                <div v-if="active == 'CashAndBank'">

                    <CashAndBankDashboard :active="'CashAndBank'"></CashAndBankDashboard>
                </div>
            </div>




        </div>
    </div>
</template>
<script>

    import clickMixin from '@/Mixins/clickMixin'
    import moment from "moment";

    export default {
       

        mixins: [clickMixin],

        data: function () {

            return {
                active: 'Dashboard',
                overView: 'Monthly',
                currency: '',
                randerChart: 0,
                income: 0,
                randerDropdown: 0,
                totalBank: 0,
                totalInvoices: 0,
                totalReturn: 0,
                expense: 0,
                purchase: 0,
                creditAmount: 0,
                earningSeries: [{
                    name: 'Earning',
                    data: []
                }],
                earningChartOption: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: []
                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yy HH:mm'
                        },
                    },
                },

                chartOptions: {
                    labels: ['Credit Invoices', 'Cash Invoices' ]
                },
                chartOptionsPaidInvoices: {
                    labels: ['UnPaid', 'Partially Paid', 'Fully Paid' ]
                },
                series9: [],
                paidInvoicesSeries: [],
                creditInvoicesSeries: [],
                chartOptionsCreditInvoices: {
                    chart: {
                        width: 380,
                        type: 'donut',
                    },
                    plotOptions: {
                        pie: {
                            startAngle: -90,
                            endAngle: 270
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    fill: {
                        type: 'gradient',
                    },
                    legend: {
                        formatter: function (val, opts) {
                            return val + " - " + opts.w.globals.creditInvoicesSeries[opts.seriesIndex]
                        }
                    },
                    title: {

                    },
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },

                seriesPurchase: [{
                    name: 'Purchase',
                    data: []
                }, {
                    name: 'Sale',
                    data: []
                }],
                chartOptionsPurchase: {
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: 'datetime',
                        categories: []
                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yy HH:mm'
                        },
                    },
                },


                loading: true,

                search: '',

                userID: 0,
                employeeId: 0,
                isChartLoad: false,
                fromDate: moment().format("DD MMM YYYY"),
                toDate: Date()
            }
        },
        watch: {

        },
        methods: {
            makeActive: function (item) {

                this.active = item;
            },
            OverViewFilterFunction: function (x) {

                
                this.overView = x;
                this.GetCashTransaction();
            },
            DetailOfProduct: function (x) {

                this.$router.push({
                    path: '/ProductDetailDashboard',
                    query: { date: this.search, productId: x }
                })
            },
            getDate: function (date) {
                return moment(date).format('l');
            },

            GetCashTransaction: function () {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.$https.get('/Company/GetTransaction?currentDate=' + root.search + '&overViewFilter=' + this.overView + '&activeTab=' + this.active, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        
                        root.series9 = [];
                        root.paidInvoicesSeries = [];
                        root.earningSeries[0].data = [];
                        root.earningChartOption.xaxis.categories = [];
                        root.seriesPurchase[0].data = [];
                        root.seriesPurchase[1].data = [];
                        root.chartOptionsPurchase.xaxis.categories = [];
                        root.income = response.data.income;
                        root.creditAmount = response.data.creditAmount;
                        root.totalInvoices = response.data.totalInvoices;
                        root.totalReturn = response.data.totalReturn;
                        root.purchase = response.data.purchase;
                        root.expense = response.data.expense;
                        root.totalCreditInvoices = response.data.totalCreditInvoices;
                        response.data.earningList.forEach(function (result) {
                            root.earningSeries[0].data.push((parseFloat(result.amount)).toFixed(0) );
                            root.earningChartOption.xaxis.categories.push(result.date );
                        });
                        response.data.salePurchaseLookUpModel.forEach(function (result) {
                            root.seriesPurchase[0].data.push((parseFloat(result.purchaseAmount)).toFixed(0) );
                            root.seriesPurchase[1].data.push(parseFloat(result.saleAmount).toFixed(0));
                            root.chartOptionsPurchase.xaxis.categories.push(result.date);
                        });
                      
                        var creditInvoices = parseInt((response.data.totalCreditInvoices / response.data.totalInvoices) * 100);
                        var cashInvoices = parseInt((response.data.totalCashInvoices / response.data.totalInvoices) * 100);
                        root.series9.push(creditInvoices);
                        root.series9.push(cashInvoices);
                        var unPaidInvoices = parseInt((response.data.unPaidInvoices / response.data.totalInvoices) * 100);
                        var partially = parseInt((response.data.partiallyInvoices / response.data.totalInvoices) * 100);
                        var fullyInvoices = parseInt((response.data.fullyInvoices / response.data.totalInvoices) * 100);
                        root.paidInvoicesSeries.push(unPaidInvoices);
                        root.paidInvoicesSeries.push(partially);
                        root.paidInvoicesSeries.push(fullyInvoices);
                        root.randerChart++;


                    }
                });
            },


        },
        created: function () {

            this.$emit('input', this.$route.name);
        },
        mounted: function () {
            

            this.currency = localStorage.getItem('currency');

            this.fromDate = moment().startOf('month').format("DD MMM YYYY");

            if (this.$session.exists()) {
                this.userID = localStorage.getItem('UserID');
                this.employeeId = localStorage.getItem('EmployeeId');
                this.fromDate = moment().startOf('month').format("DD MMM YYYY");
                //this.isDayStart();

                // this.getDataSaleIncome();
            }
          
            this.GetCashTransaction();
            this.search = moment().format("DD MMM YYYY");

            this.chartbymonth = moment().format("DD MMM YYYY");
            this.rander++;
            this.randerDropdown++;

            //this.GetInventory(this.search, 1);

        },
    }
</script>
<style scoped>

    .DashboardFontSize {
        font-size: 20px;
        color: black;
        font-weight: bold;
    }

    .NumberSize {
        font-size: 30px;
        font-weight: bold;
        color: #3178F6;
    }
</style>